<template>
  <div>
    <el-dialog class="dialogs" title="车辆出险登记" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="100px">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="费用报销人员" :label-width="labelWidth" prop="auto_id">
              <el-input v-model="dform.reimburse_user" disabled placeholder="无">
                <i slot="suffix" class="iconfont icon-yuan1" />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="费用所属单位" :label-width="labelWidth">
              <el-input v-model="dform.department" disabled placeholder="无">
                <i slot="suffix" class="iconfont icon-yuan1" />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="报销金额" :label-width="labelWidth">
              <el-input v-model="dform.amount" disabled placeholder="无">
                <i slot="suffix" class="iconfont icon-yuan1" />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="费用种类" :label-width="labelWidth">
              <el-input v-model="dform.cost_type" disabled placeholder="无">
                <i slot="suffix" class="iconfont icon-yuan1" />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="项目名称" :label-width="labelWidth">
              <el-input v-model="dform.project_name" disabled placeholder="无">
                <i slot="suffix" class="iconfont icon-yuan1" />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报销公司" :label-width="labelWidth">
              <el-input v-model="dform.firm" disabled placeholder="无">
                <i slot="suffix" class="iconfont icon-yuan1" />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="项目编号" :label-width="labelWidth">
              <el-input v-model="dform.project_no" disabled placeholder="无">
                <i slot="suffix" class="iconfont icon-yuan1" />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否有发票" :label-width="labelWidth">
              <el-radio-group disabled v-model="dform.invoice">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="detailBox">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="支付截图" :label-width="labelWidth" prop="response_file">
                <el-upload ref="uploadPay" disabled :limit="1" :file-list="payfileList" action="" :multiple="false" list-type="picture-card">
                  <!-- <i class="el-icon-plus"></i> -->
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 0px">
            <el-col :span="24">
              <el-form-item label="审批截图" :label-width="labelWidth" prop="response_pic">
                <el-upload ref="uploadApproval" disabled :file-list="approvalfileList" action="" :multiple="false" list-type="picture-card">
                  <!-- <i class="el-icon-plus"></i> -->
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="personBox">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="收据截图" :label-width="labelWidth" prop="user_submit_file">
                <el-upload ref="uploadConfirm" disabled :limit="1" :file-list="receiptfileList" action="" :multiple="false" list-type="picture-card">
                  <!-- <i class="el-icon-plus"></i> -->
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="cancelfun()">取 消</el-button> -->
        <!-- <el-button type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button> -->
        <!-- <el-button type="primary" @click="beforeSub">确 定</el-button> -->
      </span>
    </el-dialog>
    <!-- </div> -->
    <el-dialog :visible.sync="dialogVisibleImg">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
// import { AddImg } from '@/api/personnel'
import { Upimg } from '@/api/upimg'
import { GetautoList, addLosses, editLosses } from '@/api/car'
import { getList as getCarDetail } from '@/api/carindetail'

export default {
  name: 'dia',
  props: {
    dialogVisible: Boolean,
    isAddorEdit: String,
    infoObj: Object
  },
  data() {
    return {
      labelWidth: '120px',
      multipleSelection: [],
      loading: false,
      dialogVisibleImg: false,
      dialogImageUrl: '',
      receiptfileList: [],
      payfileList: [],
      approvalfileList: [],

      dform: {
        amount: '',
        department: '',
        cost_type: '',
        detail: '',
        firm: '',
        invoice: '',
        project_name: '',
        project_no: '',
        reimburse_no: '',
        reimburse_time: '',
        reimburse_user: '',
        pay_img: [],
        approval_img: [],
        receipt_img: []
      }
    }
  },
  async mounted() {},
  methods: {
    // 提交前处理
    async beforeSub() {
      if (this.multipleSelection.length !== 0) {
        this.dform.ins_ids = []
        this.dform.ins_money = []
        this.multipleSelection.map(item => {
          this.dform.ins_ids.push(item.ins_id)
          this.dform.ins_money.push(Number(item.ins_money) * 100)
        })
      } else {
        await this.$message.error('请选择险种')
      }
      console.log(this.dform)
      this.$refs['dform'].validate(async v => {
        if (v && this.multipleSelection.length !== 0) {
          // && this.dform.response_pic.length !== 0 && this.dform.user_submit_file.length !== 0
          // if (this.dform.is_response_file === 1 && this.dform.response_file.length === 0) {
          //   // alert(3)
          //   return
          // }
          let subObj = JSON.parse(JSON.stringify(this.dform))
          if (this.isAddorEdit === 'add') {
            // alert(1)
            const { data } = await addLosses(subObj)
            if (data.code !== 200) {
              return this.$message.error('添加失败')
            } else {
              this.$message.success('添加成功')
            }
          } else if (this.isAddorEdit === 'edit') {
            // alert(2)
            const { data } = await editLosses(subObj)
            if (data.code !== 200) {
              return this.$message.error('修改失败')
            } else {
              this.$message.success('修改成功')
            }
          }
          this.$emit('update')
          this.handleClose()
        }
      })
    },
    clearAll() {
      this.dform = {
        amount: '',
        department: '',
        cost_type: '',
        detail: '',
        firm: '',
        invoice: '',
        project_name: '',
        project_no: '',
        reimburse_no: '',
        reimburse_time: '',
        reimburse_user: '',
        pay_img: [],
        approval_img: [],
        receipt_img: []
      }
      this.receiptfileList = []
      this.payfileList = []
      this.approvalfileList = []
    },

    // -------------------------------------------------------------------------------
    handlePictureCardPreview(file) {
      console.log(file, 'file')
      this.dialogImageUrl = file.url
      this.dialogVisibleImg = true
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('handleClose')
      this.clearAll()
    }
  },
  watch: {
    dialogVisible: {
      handler(newV, oldV) {
        console.log(newV, 'dialogVisible')
      },
      deep: true,
      immediate: true
    },
    isAddorEdit: {
      handler(newV, oldV) {
        console.log(newV, 'isAddorEdit')
      },
      deep: true,
      immediate: true
    },
    infoObj: {
      async handler(newV, oldV) {
        console.log(newV, 'infoObj')
        let newObj = JSON.parse(JSON.stringify(newV))
        this.dform.department = newObj.department
        this.dform.amount = newObj.amount / 100
        this.dform.amount = this.dform.amount.toFixed(2)
        this.dform.cost_type = newObj.cost_type
        this.dform.detail = newObj.detail
        this.dform.firm = newObj.firm
        this.dform.project_name = newObj.project_name
        if (newObj.invoice === 1) {
          this.dform.invoice_name = '有发票'
        } else {
          this.dform.invoice_name = '无发票'
        }
        this.dform.invoice = newObj.invoice
        this.dform.project_no = newObj.project_no
        this.dform.project_no = newObj.project_no
        this.dform.reimburse_user = newObj.reimburse_user
        this.dform.reimburse_time = newObj.reimburse_time
        this.dform.reimburse_no = newObj.reimburse_no
        newObj.approval_img.map(item => {
          this.approvalfileList.push({ url: item.image_url })
        })
        this.dform.approval_img = newObj.approval_img
        newObj.pay_img.map(item => {
          this.payfileList.push({ url: item.image_url })
        })
        this.dform.pay_img = newObj.pay_img
        if(newObj.receipt_img.length!==0){
          newObj.receipt_img.map(item => {
          this.receiptfileList.push({ url: item.image_url })
        })
        this.dform.receipt_img = newObj.receipt_img
        }
      },
      deep: true
      // immediate: true
    }
  }
}
</script>
<style scoped lang="less">
.dialogs {
  /deep/ .el-date-editor {
    width: 100% !important;
  }
  /deep/ .el-select {
    width: 100% !important;
  }
  /deep/ .el-input__inner {
    width: 100% !important;
  }

  .detailBox {
    margin-top: 50px;
  }
  .personBox {
  }
}
</style>
